import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useScript from "../Common/Utilities/useScript";
import {getBrands} from "../HTTPS/apisInterface";
import {ThemeContext} from "../Context/ThemeContext";

export default function (props) {
  const history = useHistory();
  useScript('https://app.convertful.com/Convertful.js?owner=31039',"convertful-api");
  const [loader,setLoaderVisibility] = useState(true);
  const [brands,setBrands] = useState([]);
  const isMobile = useMediaQuery('(max-width:769px)');
    const {  toggleTheme } = useContext(ThemeContext);
  // const fetchApiData = async ()=>{
  //     const response =  await getBrands();
  //     setLoaderVisibility(false)
  //     setBrands(response);
  // }
  useEffect( ()=>{
      setLoaderVisibility(false)
      setBrands(props.brands);
      toggleTheme('dark')
  },[])


    return(<React.Fragment>
       <div   className={"homepage-content white"}>
           {loader  &&
               <div style={{display:'flex',justifyContent:'center'}} >
                   <CircularProgress style={{width:'40px',color:'gray'}} />
               </div>
           }
         <div className={"container brands-container"}>

             {brands.length !== 0 ?
                     <React.Fragment>
                         {brands.map(item=>{
                             return <div key={item.name} >
                                 <div style={{height:isMobile? '200px':'230px'}} className={'logo-container'}>
                                     <div  className={'logo-container-image'}>
                                         <img width={300}  height={300}  style={{objectFit:'fit-content'}} alt={item.name} src={item.image} />

                                     </div>
                                     <Button className={`afspraak-button`} onClick={()=>{
                                         props.reactGA.event({
                                             category: "Brand",
                                             action: "Select",
                                             label: item.name.toLowerCase()
                                         })
                                         history.push(`/${item.permalink}${history.location.search}`,{employees:item.employees})}} >Boek een afspraak</Button>
                                 </div>
                             </div>
                         })}
                     </React.Fragment>
                 : ''}
         </div>
       </div>
     </React.Fragment>
  )
}
