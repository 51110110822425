import React, {useEffect, useState} from 'react';
import './App.scss';
import {
    BrowserRouter,
    Redirect,
    Switch,
    Route, useHistory, useParams,
} from "react-router-dom";

import NotFound from "./Components/NotFound/NotFound";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";

import Form from "./Components/Form/Form";
import ReactGA from 'react-ga';
import HomePage from "./Components/HomePage/HomePage";
import BookingSystem from "./Components/BookingSystem/BookingSystem";
import {BookingToolThemeProvider} from "./Components/Context/ThemeContext";
import {getBrands} from "./Components/HTTPS/apisInterface";

const tagManagerArgs = {
    gtmId: 'GTM-MHLT7G6'
}


function App() {

    const [theme, setTheme] = useState('light');
    const [isRedirectURL,setIsRedirectUrl] = useState(null);

    const [brands,setBrands] = useState(null);

    useEffect(()=>{
    getBrands().then(response=>{
        setBrands(response);
    })
    },[])
    return (
        <React.Fragment>
            <BookingToolThemeProvider>
                <BrowserRouter>
                    <Header brand={null}/>
                    {brands && <Route
                        render={({location})=>{
                            return (
                                <Switch location={location}>
                                    <Route  exact path="/" render={
                                        ({location})=>{
                                            ReactGA.pageview(window.location.pathname + window.location.search);
                                            return <HomePage  reactGA={ReactGA} brands={brands}   />
                                        }}/>
                                    <Route  exact path="/:brand" render={ ({location})=>{
                                        ReactGA.pageview(window.location.pathname + window.location.search);
                                        return <BookingSystem brands={brands} reactGA={ReactGA} urlSearchParam={isRedirectURL} {...location.state}/>
                                    }}/>
                                    <Route  exact path="/:brand/:brandLocation/booking" render={ ({location})=>{
                                        ReactGA.pageview(window.location.pathname + window.location.search);
                                        return <Form brands={brands} reactGA={ReactGA} urlSearchParam={isRedirectURL} {...location.state}/>
                                    }}/>
                                    <Route path="*" component={NotFound}/>

                                </Switch>)
                        }}
                    />}
                    <Footer theme={theme}/>
                </BrowserRouter>
            </BookingToolThemeProvider>
        </React.Fragment>
    );
}

export default App;
