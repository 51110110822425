import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";


export default function (props){
	return(<div style={{marginBottom:'55px'}}>
		<div  className={'flex'}><LocationOnIcon style={{marginRight:'10px',color:'#5CAF95'}}/><a rel={'noopener noreferrer'} target={'_blank'} href={props.location.locationURL}>{props.location.locationName}</a></div>
		<div style={{marginTop:'10px'}} className={'flex'}><PhoneIcon style={{marginRight:'10px',color:'#5CAF95'}}/> <a href={`tel:${props.tele.replace(/\\s/g, '')}`}>{props.tele}</a></div>
		<div style={{marginTop:'10px'}} className={'flex'}><MailIcon style={{marginRight:'10px',color:'#5CAF95'}}/><a href={`mailto:${props.email}`}>{props.email}</a></div>
	</div>)
}
