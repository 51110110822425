import axios from 'axios';
const backendURL = "https://cms-carrosseriemetropool.purplepanda.be";
const namespace = "/wp-json/pp-json";
const url = backendURL + namespace;
let cancelToken;

const get = async (uri)=>{
	const res = await axios.get(`${url}/${uri}`);
	return res.data;
}
const post = async (uri,data)=>{

	const res = await  axios.post(`${url}/${uri}`,JSON.stringify(data),{ cancelToken:cancelToken?.token,headers: { 'content-type': 'application/x-www-form-urlencoded' }});
	return res.data;
}



export const getLocations = async ()=>{
	return get(`getLocations`);
}

export const getBrands = async()=>{
	return get('getBrands')
}

export const getSalesEmployee = async(location,dateParam= null)=>{
let	queryParam = "";
	if(dateParam != null){
		queryParam=`?date=${dateParam}`
	}
	//Check if there are any previous pending requests
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel("Operation canceled due to new request.");
	}
	cancelToken = axios.CancelToken.source();
	return (await axios.get(`${url}/getLocationData/${location}${queryParam}`,{cancelToken:cancelToken?.token})).data;
}

export const sendEmail = async (formData)=>{
	return post(`sendEmails`,formData);
}

export const checkEmployeeAppointment = async (formData)=>{
	return post(`isEmployeeBusy`,formData)
}
export const getCarByCarName = async (carName)=>{
	return get(`getCarByCarName/${carName}`);
}
export const getFooterData = async ()=>{
	return get(`getFooterData`);
}
export const getNextAvailableTime = async(email,date)=>{
	return get(`getNextAvailableTime/${email}?date=${date}`)
}
