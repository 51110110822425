import React, {useEffect, useState} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import {useHistory} from 'react-router-dom';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CommuteIcon from '@material-ui/icons/Commute';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NoteIcon from '@material-ui/icons/Note';
import VideocamIcon from '@material-ui/icons/Videocam';
export default  function (props) {
	const history = useHistory();
	const links = history.location.pathname === "/" ? ['']:history.location.pathname.split("/");
	const [brand,setBrand] = useState(null);

	useEffect(()=>{
		const brand = props.brands.filter(brand=>brand.permalink === history.location.pathname.split("/")[1])
		if(brand.length >= 1 ){
			setBrand(brand[0]);
		}else{
			history.push("/");
		}
	},[])
	return (
		 <div className={"brand-container"}>
			 <div style={{backgroundImage:`url(https://cms-carrosseriemetropool.purplepanda.be/wp-content/uploads/2021/12/image-1.png)`}} className={`brand-banner`}>
			 </div>
			 <div className={'container container-middle'}>
				 <div className={'row'}>
					 <div className={'col-lg-12 col-md-12 col-sm-12'}>
						 <Breadcrumbs style={{paddingTop:'10px'}}  separator={<NavigateNextIcon style={{color:`#5CAF95`}} fontSize="small" />} aria-label="breadcrumb">
							 <Link  style={{color:`#5CAF95`}}  onClick={()=>{history.push(`/${history.location.search}`)}}  className={`link anchorTag`}>
								 <HomeIcon className={'icon'} />
								 Locations
							 </Link>
							 {links.map((item,index)=>{
							 	if(index === 1){
									return  <Link style={{color:`${links.length === index + 1 ?'unset': '#5CAF95'}`}}  key={item-index}   color="inherit" onClick={()=>{
										if(index + 1 === links.length){
											return;
										}
										history.push(`/${history.location.pathname.split('/')[1]}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<LocationOnIcon className={'icon'}/>
										{brand?.breadcrumb}
									</Link>
								}
							 	if(index === 2){
									return  <Link   style={{color:`#5CAF95`}} key={item-index}  color="inherit" onClick={()=>{
										if(index + 2 === links.length){
											return;
										}
										history.push(`/${history.location.pathname.split('/')[1]}/${history.location.pathname.split('/')[2]}${history.location.search}`)}}  className={`link ${index + 2 !== links.length ? 'anchorTag' :''}`}>
										{item === "new" ? <FiberNewIcon className={'icon'}/> : item === "used" ? <AutorenewIcon className={'icon'}/> : <CommuteIcon className={'icon'}/> }
										{item.charAt(0).toUpperCase()+ item.slice(1)}
									</Link>
								}
							 	if(index === 3 && props?.location){

								return 	<Link style={{color:`#0C2E40`}}   key={item-index}  color="inherit"
										onClick={()=>{
									if(index+1 === links.length){
										return;
									}
									history.push(`/`)}}
									className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									{props?.location.permalink === "videocall" ?<VideocamIcon className={'icon'}/> :	<LocationOnIcon className={'icon'}/>}
										{props?.location.name}
									</Link>
								}
							 	if(index === 4){
									return <Link style={{color:`'#0C2E40'`}}   key={item-index} color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<NoteIcon className={'icon'}/>
										Form
									</Link>
								}
							 /*	return <Link   style={{color:`#0C2E40`}}  key={item-index} color="inherit" onClick={()=>{
									if(index+1 === links.length){
										return;
									}

							 		history.push(`/`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									<DriveEtaSharpIcon className={'icon'}/>
									{item}
								</Link>*/

							 })}
						 </Breadcrumbs>
					 </div>
				 </div>
				 <div style={{paddingTop:'10px'}} className={'row'}>
					 {props?.childComponent}
				 </div>
			 </div>

		 </div>
	)
}
