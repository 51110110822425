import React from "react";
import {carBrands} from "../../../AppConstants";
import {useHistory} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export default function (props) {
const history = useHistory();
  const isMobile = useMediaQuery('(max-width:769px)');

    return(
        <header style={{backgroundColor:'#2A2C35'}} id={'header'} >
            <div className={"container"}>
             <div className={"row"}>
            <div style={{height:'55px',cursor:'pointer',display:'flex',justifyContent:'space-between'}} className={"col-lg-12 logo"}>
                <img onClick={()=>{history.push('/')}} className={'jorssen'} alt="Jorssen" src="/assets/img/carrosserie-logo.png"/>
              {props.brand &&  <img  style={{maxHeight:'100%',float:'right',width:`${isMobile && (props.brand.name.toLowerCase() === carBrands.mini.name) ? '26%':'unset'}`}} alt={`${props.brand.name} not found`}  src={props.brand.image}/>}
            </div>

        </div>
            </div>
        </header>
    )
}
