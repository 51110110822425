import React, { useState } from 'react';
import { createContext } from 'react';

const ThemeContext = createContext();

const BookingToolThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('light'); // Example theme state

    const toggleTheme = (theme) => {
        setTheme(theme);
    };

    const contextValue = {
        theme,
        toggleTheme,
    };

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    );
};

export { BookingToolThemeProvider, ThemeContext };