import React, {useContext, useEffect, useState} from "react";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import LocationFooter from "../LocationFooter/LocationFooter";
import {getFooterData} from "../../HTTPS/apisInterface";
import {useHistory} from "react-router-dom";
import {ThemeContext} from "../../Context/ThemeContext";
export default function (props) {
    const [footerData,setFooterData] = useState([]);
    const { theme } = useContext(ThemeContext);
    const fetchFooterData = async()=>{
        getFooterData().then(response=>{
            setFooterData(response)
    })
    }
    useEffect(()=>{
        fetchFooterData();
    },[])


    return(<footer className={theme === 'light' ? 'footerContainerLight' :'footerContainer'} style={{backgroundColor:props.backgroundColor}} >
        <div style={{paddingTop:'50px'}} className={'container'}>
            <div className={'row'}>
              {footerData ?<React.Fragment>
                <div className={'col-lg-6 col-sm-12'}>
                  {footerData.map(location=>{
                    return <LocationFooter key={Math.random()} location={{locationName:location.name,locationURL:location.url}} tele={location.number} email={location.email}/>
                  })}
                </div>
                <div className={'col-lg-6 col-sm-12'} >
                  <a rel="noopener noreferrer" target={'_blank'} href={'https://jorssen.be/mini/cookies/'}>Cookie Policy</a>
                  <a rel="noopener noreferrer" style={{marginLeft:'20px'}} target={'_blank'} href={'https://jorssen.be/mini/privacy-policy/'}>Privacy Policy</a>
                </div>

              </React.Fragment>:''}
            </div>
        </div>
    </footer>)
}
