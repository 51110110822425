import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';



export default function (props) {

	const useStyles = makeStyles({
		root: {
			maxWidth: 345,
		},
	});
	const classes = useStyles();
	const history = useHistory();

	return (
		 <Card style={props.style} className={classes.root}>
			 <CardActionArea onClick={()=>{
			 	if(props.isClickable === false)
				{
					return;
				}
			history.push(`/${props.currentLocation.permalink}${history.location.search}`,{currentLocation:props.currentLocation})
			 }}>
				 <CardMedia
						component="img"
						alt={props.currentLocation.name}
						height="140"
						style={{objectFit:"cover"}}
						image={props.currentLocation.image}
						title={props.currentLocation.name}
				 />
				 <CardContent>
					 <Typography style={{fontFamily:'obvia,sans-serif'}} gutterBottom variant="h5" component="h2">
						 {props.currentLocation.name}
					 </Typography>
					 {props.brandCondition ? <Typography style={{fontFamily:'obvia,sans-serif'}} variant="body2" color="textSecondary" component="p">
						 {props.brandCondition =="new" ? "New" :props.brandCondition == "used" ? "Used":"Fleet"}
					 </Typography> :''}

				 </CardContent>
			 </CardActionArea>
		 </Card>
	);
}
